import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header/Header";


/*
    Since all content in UI occurs with same Banner and Footer,
    we use a Layout Container to create the screen. The Outlet component
    is from the router so will change as routes change.
*/
const Layout = ({user, signOut}) => {
    return (
        <div className="layout" >
            <Outlet></Outlet>
        </div>
    )
}

export default Layout;