import React, {useState} from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Tooltip from '@mui/material/Tooltip';
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import "./ResumeRow.css";

function ResumeRow({row}) {
    const [open, setOpen] = useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
            <TableCell>
                <Tooltip title="Show Resource" placement="left">
                    <IconButton
                    aria-label="expand row"
                    onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon fontSize="small"/> : <KeyboardArrowDownIcon fontSize="small"/>}
                    </IconButton>
                </Tooltip> 
            </TableCell>
            <TableCell align="left">{row["Resource Full Name"]}</TableCell>
            <TableCell align="left">{row["Resource Job Profile"]}</TableCell>
            <TableCell align="right">
                {
                    row.matchingSkills === undefined ? 
                        ""
                        :
                        row.matchingSkills.map((skill) => ( 
                            <Chip sx={{
                                height: 'auto',
                                '& .MuiChip-label': {
                                    display: 'block',
                                    whiteSpace: 'normal',
                                },
                                margin: .2
                                }} color='primary' key={skill} label={skill} />
                        ))
                }
            </TableCell>
            <TableCell align="right">
                <>
                    {row.numMatches}
                </>
            </TableCell>
        </TableRow>
        <TableRow >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{marginTop: "15px", marginLeft: "15px"}}>
                <Stack spacing={2}>
                    <Typography variant="subtitle1">
                        <strong>Enterprise ID:</strong> {row["Resource Enterprise ID"]}
                    </Typography>
                    <Typography variant="subtitle1">
                        <strong>Job Family:</strong> {row["Resource Job Family"]}
                    </Typography>
                    <Typography variant="subtitle1">
                        <strong>Management Level:</strong> {row["Resource Management Level"]}
                    </Typography>
                    <Typography variant="subtitle1">
                        <strong>Skills:</strong>
                        <ul className="resourceSkills">
                            {row["Resource Skills"].split("|").map((skill) => (
                                <li key={skill}>
                                    <Typography variant="body2">
                                        {skill}
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    </Typography>
                </Stack>
                </Box>           
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  export default ResumeRow;