
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import Chip from "@mui/material/Chip";
import Autocomplete from '@mui/material/Autocomplete';
import logo from "../../logo.svg";

import "./Sidebar.css";

export default function Sidebar({handleFileUpload, setSkills, clearAll, possibleSkills}) {
    const [currentSkills, setCurrentSkills] = useState([]);
    const [levels, setLevels] = useState({
      Master: true,
      Expert: false,
      Intermediate: false,
      Advanced: false,
      Beginner: false
    });

    const handleValueChange = (val) => {
      if (val !== null) {
        let newSkills = []
        Object.keys(levels).map((level) => {
          if (levels[level]) {
            let fullSkill = val + " (" + level + ")";
            newSkills.push(fullSkill)
          }
        })
        setCurrentSkills([ ...currentSkills, ...newSkills ]);
        setSkills([ ...currentSkills, ...newSkills]);
      }
    }

    const handleChange = (event) => {
      setLevels({
        ...levels,
        [event.target.name]: event.target.checked,
      });
    };
  

    const handleOptionDelete = (value) => {
      setCurrentSkills(currentSkills.filter(option => option !== value));
      setSkills(currentSkills.filter(option => option !== value));
    }

    const handleClear = () => {
      clearAll();
      setSkills([]);
      setCurrentSkills([]);
      setLevels({
        Master: false,
        Expert: false,
        Intermediate: false,
        Advanced: false,
        Beginner: false
      })
    }

    return (
        <Box
        className="sidebar"
        sx={{
            width: "300px",
            textAlign: "center",
            padding: "15px",
            height: "100dvh"
        }}
        >
        <Stack spacing={3}>
          <Box sx={{marginBottom: "50px", marginTop: "15px"}}>
            <label htmlFor="upload-resume">
              <input
                id="upload-resume"
                name="upload-resume"
                style={{ display: 'none' }}
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={handleFileUpload}
                className="form-control-file"
              />
              <Fab
                color="primary"
                size="medium"
                component="span"
                aria-label="add"
                variant="extended"
              >
                <AddIcon /> Upload
              </Fab>
            </label>
          </Box>
          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <FormLabel component="legend">Choose Skill Level(s)</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={levels.Master} onChange={handleChange} name="Master" />
                }
                label="Master"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={levels.Expert} onChange={handleChange} name="Expert" />
                }
                label="Expert"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={levels.Advanced} onChange={handleChange} name="Advanced" />
                }
                label="Advanced"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={levels.Intermediate} onChange={handleChange} name="Intermediate" />
                }
                label="Intermediate"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={levels.Beginner} onChange={handleChange} name="Beginner" />
                }
                label="Beginner"
              />
            </FormGroup>
        </FormControl>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          size="small"
          onChange={(event, newValue) => {
            handleValueChange(newValue);
          }}
          options={possibleSkills}
          renderInput={(params) => <TextField {...params} label="Skills" />}
        />
        <Box>
          <List
            sx={{
              width: '100%',
              maxWidth: 360,
              bgcolor: 'background.paper',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 180,
              '& ul': { padding: 0 },
            }}
          >
            {currentSkills.map((skill) => (
              <ListItem key={skill}>
                <Chip sx={{
                  height: 'auto',
                  '& .MuiChip-label': {
                      display: 'block',
                      whiteSpace: 'normal',
                  },
                  margin: .1
                  }} color='primary' label={skill} key={skill} onDelete={()=>handleOptionDelete(skill)}/>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box sx={{marginTop: "10%"}}>
            <Button size="small" sx={{width: "75px"}} variant="contained" onClick={handleClear}> Clear </Button>
        </Box>
        <img src={logo} className="App-logo" alt="logo" />
        
      </Stack>
    </Box>
  )
}