import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import xlsxParser from 'xls-parser';

import Sidebar from "../../components/SideBar/Sidebar";
import ResumeTable from "../../components/ResumeTable/ResumeTable";

const Home = () => {
    const [people, setPeople ] = useState([])
    const [possibleSkills, setPossibleSkills] = useState([])
    const [skills, setSkills] = useState([])

    useEffect(() => {
        let updatedPeople = []
        people.map((person) => {
            let text2 = person["Resource Skills"]
            let skillsNew = []
            try {
                skills.map((skill) => {
                    if (text2.includes(skill)) {
                        skillsNew.push(skill)
                    }
                })
            } catch {
                console.log("No Skills")
            }
            
            person.matchingSkills = skillsNew
            if (person.matchingSkills === undefined) {
                console.log("undefined")
                person.matchingSkills = []
            }
            person.numMatches = skillsNew.length
            updatedPeople.push(person)
        setPeople(updatedPeople)  
  });
    }, [skills])

    const createUniqueSkills = (data) => {
        let allSkills = []
        data.map((da) => {
            try {
                let personSkills = da["Resource Skills"].split(" | ")
                personSkills.map((personSkill) => {
                    let removePrefix = personSkill.split(" - ")[1]
                    let removeSuffix = removePrefix.split(" (")[0]
                    allSkills.push(removeSuffix)
                })
            } catch {
                console.log("No Skills")
            }
        })
        let allUniqueSkills = new Set(allSkills)
        let allUniqueSkillsArray = Array.from(allUniqueSkills)
        allUniqueSkillsArray.sort()
        setPossibleSkills(allUniqueSkillsArray)
    }

    const handleFileEvent = (e) => {
        xlsxParser
            .onFileSelection(e.target.files[0])
            .then(data => {
                var parsedData = data["Technology"].filter(person => person["Resource Skills"] != null)
                setPeople(parsedData)
                createUniqueSkills(parsedData)
            })
    }

    const clearAll = () => {
        setSkills([])
        setPeople([])
    }

    return (
        <div className="App" style={{ display: "flex" }}>
            <Sidebar
               handleFileUpload={handleFileEvent}
               setSkills={setSkills}
               clearAll={clearAll}
               possibleSkills={possibleSkills}
            />
            <div
                style={{
                    width: "70vw",
                    marginLeft: "25%",
                    marginRight: "5%",
                    marginTop: "2%",
                    position: "relative",
                }}
            >
                { people.length > 0 ?
                <>
                        <ResumeTable rows={people}/>
                        </>
                    :
                    
                    <Box sx={{marginTop: "20%"}}>
                        <Typography variant="h2" color="primary.contrastText">
                                Find the resources you need...
                        </Typography>
                        <Box sx={{width: "30%", marginTop: "3rem"}}>
                            <Stack spacing={3}>
                                <Typography variant="subtitle" color="primary.contrastText">
                                        Easily search across many resources for the desired skills, experience, and knowledge areas needed for your project. Quickly identify top candidates
                                </Typography>
                                <Typography variant="subtitle" color="primary.contrastText">
                                        Built by the AIX Discovery Lab
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                }
            </div>
        </div>
    );
}
export default Home;
