/**
 * 
 * Object to store different routes that
 * will be accessed throughout the website.
 * 
 */

export const ROUTE_PATHS = {
    home: "/",
    notfound: "*"
}