import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Layout from "./containers/Layout/Layout";
import { ThemeProvider } from '@mui/material';
import { theme } from "./utils/theme";
import Home from "./containers/Home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ROUTE_PATHS } from "./routes/paths";
import './App.css';

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';

Amplify.configure({
  Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID
  }
})

function MyApp() {
  return (
    <React.Fragment>
      <Authenticator
        hideSignUp={true}
      >
        {({ signOut, user }) => (
          <>
            <CssBaseline />
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <Routes>
                  <Route path={ROUTE_PATHS.home} element={<Layout user={user} signOut={signOut}/>}>
                    <Route index element={<Home />} />
                  </Route>
                </Routes>
              </BrowserRouter>
              </ThemeProvider>
          </>
        )}
      </Authenticator>
    </React.Fragment>
  );
}

export default MyApp;


