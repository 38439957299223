import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#cf82fc',
      main: '#A100FF',
      dark: '#370355',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

export const optional_theme = createTheme({
  palette: {
    primary: {
      main: '#7404c3',
    },
    secondary: {
      main: '#E0F6FD',
    },
  },
});
